<template>
    <div class="articlesContent">
        <div class="article" v-if="article.articlesCode">
            <div class="article-breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item v-if="titleKV[$route.query.aliasTitle]" :to="{
                        path: '/articlesList?aliasTitle=' + $route.query.aliasTitle +
                            ($route.query.pageNum ? '&pageNum=' + $route.query.pageNum : '') +
                            ($route.query.articlesDictsAuthorsId ? '&articlesDictsAuthorsId=' + $route.query.articlesDictsAuthorsId : '') +
                            ($route.query.types ? '&types=' + $route.query.types : '')
                    }">
                        {{
                                titleKV[$route.query.aliasTitle]
                        }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ article.title || '郑幼林雕刻艺术馆' }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="article-title">
                <span>{{ article.title }}</span>
            </div>
            <div class="article-others">
                <div class="article-others-left">
                    <div class="item">
                        作者：<span>{{ article.articlesDictsAuthorsTitle }}</span>
                    </div>
                    <div class="item">
                        类型：<span>{{ article.typesContent }}</span>
                    </div>
                </div>
                <div class="article-others-right">
                    <div class="item">
                        发布时间：<span>{{ article.releaseTime }}</span>
                    </div>
                </div>
            </div>
            <div class="article-synopsis">
                <span>内容简介：{{ article.synopsis }}</span>
            </div>
            <div class="article-content" v-html="article.contentTxt"></div>
        </div>
        <div class="empty" v-else>
            <div>
                未找到数据
            </div>
        </div>
    </div>
</template>
<script>
import { Message } from 'element-ui'
import { get } from "../../utils/request";
export default {
    data() {
        return {
            article: {},
            titleKV: {
                'XINXINXUEZI': '莘莘学子',
                'YISHUBIJI': '艺术笔记',
                'YISHUJIAJIANJIE': '艺术家简介',
                'ZIXUNXINXI': '展讯信息',
                'ZUOPINXINSHANG': '作品欣赏',
            },
        }
    },
    created() {
        if (!this.$route.query.articlesCode) return Message({ message: '非法请求', type: 'error', duration: 5 * 1000 });
        this.articlesDetailFn(this.$route.query.articlesCode);
    },
    watch: {
        '$route'(to, from) {
            this.articlesDetailFn(to.query.articlesCode);
        }
    },
    methods: {
        async articlesDetailFn(articlesCode) {
            const { data: res } = await get('/public/articles/detail/' + articlesCode);
            if (res.code == 200) this.article = res.data;
            if (this.article.contentTxt) {
                this.article.contentTxt = this.article.contentTxt.replace(/<img /g,
                    '<img style="display:block;max-width: 500px;height: auto;margin: 0 auto;" ');
            }
        },
        goTo() {
            this.$router.push({
                path: "/articlesList",
                query: {
                    aliasTitle: this.$route.query.aliasTitle
                },
            });
        }
    }
}
</script>
<style lang="less" scoped>
.articlesContent {
    width: 1000px;
    margin: 0 auto;
    padding: 30px;

    .article {
        min-height: 394px;

        .article-breadcrumb {
            margin-bottom: 10px;
        }

        .article-title {
            font-size: 30px;
            text-align: center;
            font-weight: bold;
        }

        .article-others {
            display: flex;
            justify-content: space-between;
            padding: 30px 30px 0 30px;
            font-size: 16px;
            font-weight: 400;

            .article-others-left {
                .item {
                    margin-bottom: 10px;
                }
            }

            .article-others-right {
                .item {
                    margin-bottom: 10px;
                }
            }
        }

        .article-synopsis {
            background-color: #e9e8e8;
            padding: 30px;
            margin: 10px;
            border-radius: 10px;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 394px;
        font-size: 30px;
        font-weight: 400;
    }

}
</style>